import React from 'react';
import { Select as RxSelect } from '@wearejh/rx-form';
import { RxValidateFn } from '@wearejh/rx-form/lib/types';

import { ErrorFor } from './ErrorFor';

type SelectProps = {
    id?: string;
    field: string;
    label: string;
    validate?: RxValidateFn;
    options: { label: string; value: string; disabled?: boolean }[];
    required?: boolean;
};

export const Select: React.FC<SelectProps> = (props) => {
    const { label, id, field, validate, options, required } = props;
    return (
        <>
            <label htmlFor={id || field}>
                {label}
                {required && ' *'}
            </label>
            <RxSelect field={field} id={id || field} validate={validate} options={options} />
            <ErrorFor field={field} />
        </>
    );
};

Select.defaultProps = {
    required: false,
};
