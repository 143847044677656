import React, { useCallback } from 'react';
import { CustomerDataAddressInterface } from 'swagger/ts/Definitions';
import { formToCustomerAddress } from '@wearejh/m2-pwa-user/lib/utils/formToCustomerAddress';
import { Fields } from '@wearejh/m2-pwa-addresses/lib/addresses.types';
import { MessageType, Message } from '@wearejh/m2-pwa-engine/lib/types';
import Bugsnag from '@bugsnag/js';

import { Checkbox } from '../Forms/Checkbox';
import { Messages } from '../Messages/Messages';
import { PopupNewAddress } from '../Customer/PopupNewAddress';

import { Operation } from './hooks/useEditAddressesPopup';

type CheckoutNewAddressProps = {
    open: boolean;
    close();
    operation: Operation;
    onSave?(addr: CustomerDataAddressInterface);
    initialValues?: CustomerDataAddressInterface;
};

export const AccountAddressPopup: React.FC<CheckoutNewAddressProps> = React.memo((props) => {
    const { onSave, close } = props;
    const submit = useCallback(
        (values) => {
            const output = formToCustomerAddress(values);
            if (!output) {
                Bugsnag.notify('Form values could not be used to create a customer address', (event) => {
                    event.addMetadata('values', { values: values });
                });
                return;
            }
            onSave && onSave(output);
        },
        [onSave],
    );
    const isDefaultBilling = props.initialValues && props.initialValues.default_billing;
    const isDefaultShipping = props.initialValues && props.initialValues.default_shipping;
    const messages: Message[] = [];
    isDefaultBilling &&
        messages.push({
            type: MessageType.Info,
            text: 'This is your default billing address',
        });
    isDefaultShipping &&
        messages.push({
            type: MessageType.Info,
            text: 'This is your default shipping address',
        });
    return (
        <PopupNewAddress
            title={(props.operation === Operation.Edit && 'Edit Address') || 'New Address'}
            initialValues={props.initialValues}
            isOpen={props.open}
            onClose={close}
            onSave={submit}
        />
    );
});
