import React from 'react';
import { useRegionField } from '@wearejh/m2-pwa-addresses/lib/hooks/useRegionField';
import { Fields } from '@wearejh/m2-pwa-addresses/lib/addresses.types';

import { Select } from '../Forms/Select';
import { Input } from '../Forms/Input';

const classes = {} as any;

export const RegionField: React.FC<{ onChange?: () => void }> = ({ onChange }) => {
    const { hasRegionSelect, selectedCountry } = useRegionField();

    return (
        <div className={classes.root}>
            {hasRegionSelect && (
                <Select
                    label="County"
                    field={Fields.region_id}
                    id={Fields.region_id}
                    options={
                        selectedCountry.available_regions?.map((region) => {
                            return {
                                label: region.name,
                                value: region.id,
                            };
                        }) as any
                    }
                />
            )}
            <div hidden={hasRegionSelect}>
                <Input field={Fields.region} label="County" onChange={onChange} />
            </div>
        </div>
    );
};
