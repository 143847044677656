import { usePCAPredict } from 'src/hooks/usePCAPredict';
import { useFormApi } from '@wearejh/rx-form/lib/hooks/useFormApi';
import { useDeps } from 'src/hooks/useDeps';
import { useCallback } from 'react';

export function PCAPredict(props: { prefix: string; country_code: string; showFields() }) {
    const { PCA_FORM_KEY } = useDeps().env;
    const formApi = useFormApi();

    const setValues = useCallback(
        (address) => {
            props.showFields();
            formApi.setValues({
                'address.street1': address.FormattedLine1,
                'address.street2': address.FormattedLine2,
                'address.postcode': address.PostalCode,
                'address.city': address.City,
                region: address.Province,
                'address.country_id': address.CountryIso2,
            });
        },
        [formApi, props],
    );

    /**
     * The field mappings
     * @param pca
     * @param prefix
     */
    const getFields = useCallback(
        (pca) => {
            return [
                {
                    element: `${props.prefix}street1`,
                    field: 'Line1',
                    mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE,
                },
                {
                    element: `${props.prefix}street2`,
                    field: 'Line2',
                    mode: pca.fieldMode.POPULATE,
                },
                {
                    element: `${props.prefix}postcode`,
                    field: 'PostalCode',
                    mode: pca.fieldMode.POPULATE,
                },
                {
                    element: `${props.prefix}city`,
                    field: 'City',
                    mode: pca.fieldMode.POPULATE,
                },
                {
                    element: `${props.prefix}region`,
                    field: 'Province',
                    mode: pca.fieldMode.POPULATE,
                },
            ];
        },
        [props.prefix],
    );

    usePCAPredict(PCA_FORM_KEY, props.country_code, props.prefix, setValues, getFields);

    return null;
}
